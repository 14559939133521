<template>
  <div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input
            @input="searchMembers"
            v-model="searchQuery"
            placeholder="Search"
            type="text"
            class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'first_name'" class="text-nowrap">
          <span class="text-nowrap">
            {{ props.row.first_name }} {{ props.row.last_name }} <br />
          </span>
        </span>
        <span v-else-if="props.column.field === 'username'" class="text-nowrap">
          <span class="text-nowrap" v-if="props.row.retro_username">
            {{ props.row.retro_username }} <br />
          </span>
          <span class="text-nowrap" v-if="props.row.gen_username">
            {{ props.row.gen_username }}<br />
            <b-badge
              variant="light-success"
              v-if="props.row.register_method === 'gopro_web'"
              >GP24B</b-badge
            >
          </span>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <b-button
            variant="relief-success"
            class="btn-icon mr-1"
            @click="open_new(props.row._id)"
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['3', '5', '10']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import axios from "axios";
import moment from "moment";

export default {
  components: {
    BButton,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BRow,
    BCol,
  },
  data() {
    return {
      pageLength: 30,
      dir: false,
      columns: [
        {
          label: "ชื่อ",
          field: "first_name",
        },
        {
          label: "Username",
          field: "username",
        },
        {
          label: "เบอร์โทรศัพท์",
          field: "phone",
        },

        {
          label: "Email",
          field: "email",
        },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      searchQuery: "",
    };
  },
  computed: {},
  methods: {
    open_new(id) {
      window.open("/member/view/" + id, "_blank");
    },
    comma(x) {
      if (x != null) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      } else {
        return x;
      }
    },
    searchMembers: _.debounce(function () {
      const request = {
        search_text: this.searchQuery,
      };

      this.$http({
        method: "POST",
        url: "/member/list/filtered-main",
        data: request,
      })
        .then((x) => {
          console.log("search ", x.data.data);
          this.rows = x.data.data;
        })
        .catch((e) => {
          console.log("error", e);
        });
    }, 500),
  },
  created() {
    this.$http({
      method: "GET",
      url: `/member/`,
    }).then((x) => {
      this.rows = x.data.data;
    });
  },
  filters: {
    day: function (date) {
      return moment(date).format("D/M");
    },
    time: function (date) {
      return moment(date).format("HH:mm");
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
