<template>
  <div>
    <b-modal
      id="modal-add-member"
      ref="modal-add-member"
      title="เพิ่มสมาชิกใหม่"
      no-close-on-backdrop
      size="lg"
      hide-footer
    >
      <validation-observer ref="addMember">
        <b-form @submit.prevent="addMember">
          <!-- ชื่อ -->
          <b-form-group label="ชื่อ">
            <validation-provider
              #default="{ errors }"
              name="First name"
              :rules="{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }"
            >
              <b-form-input
                v-model="form.first_name"
                :state="errors.length > 0 ? false : null"
                placeholder="ภาษาไทยเท่านั้น"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- นามสกุล -->
          <b-form-group label="นามสกุล">
            <validation-provider
              #default="{ errors }"
              name="Last name"
              :rules="{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }"
            >
              <b-form-input
                v-model="form.last_name"
                :state="errors.length > 0 ? false : null"
                placeholder="ภาษาไทยเท่านั้น"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- /^[ก-ฮะ-์\s] -->

          <!-- เลขที่บัตรประชาชน -->
          <b-form-group label="เลขที่บัตรประชาชน">
            <validation-provider
              #default="{ errors }"
              name="Card ID"
              rules="required|digits:13"
            >
              <b-form-input
                v-model="form.card_id"
                :state="errors.length > 0 ? false : null"
                placeholder="เฉพาะตัวเลขไม่ต้องเว้นวรรค"
                maxlength="13"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- เบอร์โทร -->
          <b-form-group label="เบอร์โทรศัพท์มือถือ">
            <validation-provider
              #default="{ errors }"
              name="Phone"
              rules="required|digits:10"
            >
              <b-form-input
                v-model="form.phone"
                :state="errors.length > 0 ? false : null"
                placeholder="ไม่ต้องใส่ขีด"
                maxlength="10"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- Email -->
          <b-form-group label="Email">
            <validation-provider
              #default="{ errors }"
              name="Email"
              rules="required|email"
            >
              <b-form-input
                v-model="form.email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <!-- วันเกิด -->
          <b-form-group>
            <day-month-year
              :day_text="'วันเกิด'"
              :year_range="{
                max_year: this_year - 18,
                min_year: this_year - 80,
              }"
              @PickerUpdate="BirthdayUpdate"
            ></day-month-year>
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Birth Day"
            >
              <b-form-input v-model="form.birth_day" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Birth Month"
            >
              <b-form-input v-model="form.birth_month" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Birth Year"
            >
              <b-form-input v-model="form.birth_year" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <small>ผู้เช่าต้องมีอายุมากกว่า 18 ปีเท่านั้น</small>
          </b-form-group>

          <!-- เพศ -->
          <b-form-group label="เพศ">
            <validation-provider #default="{ errors }" name="Gender">
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="form.gender"
                  name="some-radios"
                  value="male"
                >
                  ชาย
                </b-form-radio>
                <b-form-radio
                  v-model="form.gender"
                  name="some-radios"
                  value="female"
                >
                  หญิง
                </b-form-radio>
                <b-form-radio
                  v-model="form.gender"
                  name="some-radios2"
                  value="other"
                >
                  อื่นๆ
                </b-form-radio>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ที่อยู่ -->
          <b-form-group label="ที่อยู่">
            <validation-provider
              #default="{ errors }"
              name="Address"
              rules="required"
            >
              <b-form-input
                v-model="form.address1"
                :state="errors.length > 0 ? false : null"
                placeholder="เลขที่ อาคาร/หมู่บ้าน หมู่ ซอย"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ถนน -->
          <b-form-group label="ถนน">
            <validation-provider
              #default="{ errors }"
              name="Road"
              rules="required"
            >
              <b-form-input
                v-model="form.road"
                :state="errors.length > 0 ? false : null"
                placeholder="ถนน"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- ที่อยู่ group -->
          <b-form-group>
            <p>กรอก <u>แขวง</u> หรือ <u>เขต</u> หรือ <u>รหัสไปรษณีย์</u></p>
            <address-selector
              @addressSelected="addressSelected"
            ></address-selector>
            <b-alert
              show
              variant="success"
              v-if="
                (address_group.province != null) &
                (address_group.province == 'กรุงเทพมหานคร')
              "
              class="mt-1"
            >
              <div class="alert-body">
                <div v-if="(form.address1 != null) & (form.road != null)">
                  {{ form.address1 }} ถนน {{ form.road }}
                </div>
                แขวง{{ address_group.district }} เขต{{ address_group.amphoe }}
                {{ address_group.province }} {{ address_group.zipcode }}
              </div>
            </b-alert>
            <b-alert
              show
              variant="success"
              v-if="
                (address_group.province != null) &
                (address_group.province != 'กรุงเทพมหานคร')
              "
              class="mt-1"
            >
              <div class="alert-body">
                {{ form.address1 }} ถนน {{ form.road }} <br />
                ตำบล <u>{{ address_group.district }}</u> อำเภอ
                <u>{{ address_group.amphoe }}</u> จังหวัด
                <u>{{ address_group.province }}</u> {{ address_group.zipcode }}
              </div>
            </b-alert>

            <validation-provider
              rules="required"
              #default="{ errors }"
              name="Zipcode"
            >
              <b-form-input v-model="form.zipcode" class="hidden" />
              <small class="text-danger">
                <div>{{ errors[0] }}</div>
              </small>
            </validation-provider>
          </b-form-group>

          <!-- วันหมดอายุบัตรประชาชน -->
          <b-form-group>
            <day-month-year
              :day_text="'วันหมดอายุบัตร'"
              :year_range="{ max_year: this_year + 15, min_year: this_year }"
              @PickerUpdate="IdExpireUpdate"
            ></day-month-year>
            <validation-provider #default="{ errors }" name="ID Expire Day">
              <b-form-input v-model="form.id_ex_day" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider #default="{ errors }" name="ID Expire Month">
              <b-form-input v-model="form.id_ex_month" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
            <validation-provider #default="{ errors }" name="ID Expire Year">
              <b-form-input v-model="form.id_ex_year" class="hidden" />
              <small class="text-danger"
                ><div>{{ errors[0] }}</div></small
              >
            </validation-provider>
          </b-form-group>

          <!-- รหัสหลังบัตร -->
          <b-form-group label="รหัสหลังบัตรประชาชน">
            <b-row>
              <b-col>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    rules="digits:3"
                    name="laser1"
                  >
                    <!-- required| -->
                    <b-form-input
                      v-model="form.laser_1"
                      :state="errors.length > 0 ? false : null"
                      placeholder="XXX"
                      maxlength="3"
                      @input="moveToNextField($event, 2, 3)"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <h4>_</h4>
              <b-col cols="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    rules="digits:7"
                    name="laser2"
                  >
                    <!-- required| -->
                    <b-form-input
                      ref="laser2"
                      v-model="form.laser_2"
                      :state="errors.length > 0 ? false : null"
                      placeholder="XXXXXXX"
                      maxlength="7"
                      @input="moveToNextField($event, 3, 7)"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
              <h4>_</h4>
              <b-col>
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    rules="digits:2"
                    name="laser3"
                  >
                    <!-- required| -->
                    <b-form-input
                      ref="laser3"
                      v-model="form.laser_3"
                      :state="errors.length > 0 ? false : null"
                      placeholder="XX"
                      maxlength="2"
                    />
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
          <b-button variant="success" @click.prevent="validationForm">
            สมัครสมาชิก
          </b-button>
        </b-form>
      </validation-observer>

      <!-- member_id
            ชื่อ/
            นามสกุล/
            เบอร์โทร/
            email/
            ที่อยู่ (บ้านเลขที่ ถนน ซอย)  /
            แขวง/
            เขต/
            จังหวัด/
            รหัสไปรษณีย์/
            วันเดือนปีเกิด/
            เพศ/
            เลขที่บัตรประชาชน/
            วันหมดอายุบัตรประชาชน/
            member_type
            note
            facebook
            line id -->
      <!-- -ที่อยู่ตามบัตรประชาชน
            -ที่อยู่จัดส่ง
            -ที่อยู่ปัจจุบัน
            -ที่อยู่ออกใบกำกับภาษี -->
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BModal,
  BButton,
  BFormRadio,
  BAlert,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import AddressSelector from "./AddressSelector.vue";
import DayMonthYear from "./DayMonthYear.vue";
import { required } from "@validations";
import vSelect from "vue-select";
import * as axios from "axios";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    BModal,
    BButton,
    BFormRadio,
    ValidationObserver,
    ValidationProvider,
    AddressSelector,
    BAlert,
    vSelect,
    BRow,
    BCol,
    DayMonthYear,
  },
  data() {
    return {
      form: {
        address1: null,
        road: null,
        birth_day: null,
        birth_month: null,
        birth_year: null,
        district_code: null,
        id_ex_day: null,
        id_ex_month: null,
        id_ex_year: null,
        birth_day: null,
        birth_month: null,
        birth_year: null,
        zipcode: null,
      },
      address_group: {
        province: null,
      },
      this_year: new Date().getFullYear(),
    };
  },
  created() {},
  methods: {
    AddNewMember() {
      this.$http({
        method: "POST",
        url: `/member/email/register`,
        data: this.form,
      })
        .then((x) => {
          this.$refs["modal-add-member"].hide();
          this.$router.push("/member/view/" + x.data.data._id);
        })
        .catch((error) => {
          if (error.response) {
            const { data } = error.response;
            const { message } = data; // Destructure the 'data' from the response
            console.log(`Message: ${message}`);
            this.toast_error("มีข้อผิดพลาด", message);
          } else {
            this.toast_error("มีข้อผิดพลาด", error.message);
          }
        });
    },
    validationForm() {
      this.$refs.addMember.validate().then((success) => {
        if (success) {
          this.AddNewMember();
        }
      });
    },
    addressSelected(x) {
      this.form.sub_district_code = x.item.amphoe_code;
      this.form.sub_district = x.item.amphoe;
      this.form.zipcode = x.item.zipcode;
      this.form.province = x.item.province;
      this.form.district = x.item.district;
      this.address_group = x.item;
      this.form.district_code = x.item.district_code;
      this.form.province_code = x.item.province_code;
    },
    BirthdayUpdate(x) {
      this.form.birth_day = x.day;
      this.form.birth_month = x.month;
      this.form.birth_year = x.year;

      console.log("birthday", x);
    },
    IdExpireUpdate(x) {
      this.form.id_ex_day = x.day;
      this.form.id_ex_month = x.month;
      this.form.id_ex_year = x.year;

      console.log("id ex", x);
    },
    validateField(field) {
      console.log(field);
      const provider = this.$refs[field];
      // Validate the field
      return provider.validate();
    },
    moveToNextField(e, num, limit) {
      console.log(e, e.length, "OKOKOKO", num, limit);
      if (e.length === limit && num === 2) {
        this.$refs.laser2.focus();
      }
      if (e.length === limit && num === 3) {
        this.$refs.laser3.focus();
      }
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
