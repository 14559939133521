var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-add-member",attrs:{"id":"modal-add-member","title":"เพิ่มสมาชิกใหม่","no-close-on-backdrop":"","size":"lg","hide-footer":""}},[_c('validation-observer',{ref:"addMember"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.addMember($event)}}},[_c('b-form-group',{attrs:{"label":"ชื่อ"}},[_c('validation-provider',{attrs:{"name":"First name","rules":{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ภาษาไทยเท่านั้น"},model:{value:(_vm.form.first_name),callback:function ($$v) {_vm.$set(_vm.form, "first_name", $$v)},expression:"form.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"นามสกุล"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":{ required: true, regex: /^[ก-ฮะ-์ํฯ\s]+$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ภาษาไทยเท่านั้น"},model:{value:(_vm.form.last_name),callback:function ($$v) {_vm.$set(_vm.form, "last_name", $$v)},expression:"form.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"เลขที่บัตรประชาชน"}},[_c('validation-provider',{attrs:{"name":"Card ID","rules":"required|digits:13"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"เฉพาะตัวเลขไม่ต้องเว้นวรรค","maxlength":"13"},model:{value:(_vm.form.card_id),callback:function ($$v) {_vm.$set(_vm.form, "card_id", $$v)},expression:"form.card_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"เบอร์โทรศัพท์มือถือ"}},[_c('validation-provider',{attrs:{"name":"Phone","rules":"required|digits:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ไม่ต้องใส่ขีด","maxlength":"10"},model:{value:(_vm.form.phone),callback:function ($$v) {_vm.$set(_vm.form, "phone", $$v)},expression:"form.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('day-month-year',{attrs:{"day_text":'วันเกิด',"year_range":{
              max_year: _vm.this_year - 18,
              min_year: _vm.this_year - 80,
            }},on:{"PickerUpdate":_vm.BirthdayUpdate}}),_c('validation-provider',{attrs:{"rules":"required","name":"Birth Day"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.birth_day),callback:function ($$v) {_vm.$set(_vm.form, "birth_day", $$v)},expression:"form.birth_day"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Birth Month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.birth_month),callback:function ($$v) {_vm.$set(_vm.form, "birth_month", $$v)},expression:"form.birth_month"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"rules":"required","name":"Birth Year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.birth_year),callback:function ($$v) {_vm.$set(_vm.form, "birth_year", $$v)},expression:"form.birth_year"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('small',[_vm._v("ผู้เช่าต้องมีอายุมากกว่า 18 ปีเท่านั้น")])],1),_c('b-form-group',{attrs:{"label":"เพศ"}},[_c('validation-provider',{attrs:{"name":"Gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"demo-inline-spacing"},[_c('b-form-radio',{attrs:{"name":"some-radios","value":"male"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" ชาย ")]),_c('b-form-radio',{attrs:{"name":"some-radios","value":"female"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" หญิง ")]),_c('b-form-radio',{attrs:{"name":"some-radios2","value":"other"},model:{value:(_vm.form.gender),callback:function ($$v) {_vm.$set(_vm.form, "gender", $$v)},expression:"form.gender"}},[_vm._v(" อื่นๆ ")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ที่อยู่"}},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"เลขที่ อาคาร/หมู่บ้าน หมู่ ซอย"},model:{value:(_vm.form.address1),callback:function ($$v) {_vm.$set(_vm.form, "address1", $$v)},expression:"form.address1"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"ถนน"}},[_c('validation-provider',{attrs:{"name":"Road","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"ถนน"},model:{value:(_vm.form.road),callback:function ($$v) {_vm.$set(_vm.form, "road", $$v)},expression:"form.road"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('p',[_vm._v("กรอก "),_c('u',[_vm._v("แขวง")]),_vm._v(" หรือ "),_c('u',[_vm._v("เขต")]),_vm._v(" หรือ "),_c('u',[_vm._v("รหัสไปรษณีย์")])]),_c('address-selector',{on:{"addressSelected":_vm.addressSelected}}),(
              (_vm.address_group.province != null) &
              (_vm.address_group.province == 'กรุงเทพมหานคร')
            )?_c('b-alert',{staticClass:"mt-1",attrs:{"show":"","variant":"success"}},[_c('div',{staticClass:"alert-body"},[((_vm.form.address1 != null) & (_vm.form.road != null))?_c('div',[_vm._v(" "+_vm._s(_vm.form.address1)+" ถนน "+_vm._s(_vm.form.road)+" ")]):_vm._e(),_vm._v(" แขวง"+_vm._s(_vm.address_group.district)+" เขต"+_vm._s(_vm.address_group.amphoe)+" "+_vm._s(_vm.address_group.province)+" "+_vm._s(_vm.address_group.zipcode)+" ")])]):_vm._e(),(
              (_vm.address_group.province != null) &
              (_vm.address_group.province != 'กรุงเทพมหานคร')
            )?_c('b-alert',{staticClass:"mt-1",attrs:{"show":"","variant":"success"}},[_c('div',{staticClass:"alert-body"},[_vm._v(" "+_vm._s(_vm.form.address1)+" ถนน "+_vm._s(_vm.form.road)+" "),_c('br'),_vm._v(" ตำบล "),_c('u',[_vm._v(_vm._s(_vm.address_group.district))]),_vm._v(" อำเภอ "),_c('u',[_vm._v(_vm._s(_vm.address_group.amphoe))]),_vm._v(" จังหวัด "),_c('u',[_vm._v(_vm._s(_vm.address_group.province))]),_vm._v(" "+_vm._s(_vm.address_group.zipcode)+" ")])]):_vm._e(),_c('validation-provider',{attrs:{"rules":"required","name":"Zipcode"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.zipcode),callback:function ($$v) {_vm.$set(_vm.form, "zipcode", $$v)},expression:"form.zipcode"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-form-group',[_c('day-month-year',{attrs:{"day_text":'วันหมดอายุบัตร',"year_range":{ max_year: _vm.this_year + 15, min_year: _vm.this_year }},on:{"PickerUpdate":_vm.IdExpireUpdate}}),_c('validation-provider',{attrs:{"name":"ID Expire Day"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.id_ex_day),callback:function ($$v) {_vm.$set(_vm.form, "id_ex_day", $$v)},expression:"form.id_ex_day"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"name":"ID Expire Month"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.id_ex_month),callback:function ($$v) {_vm.$set(_vm.form, "id_ex_month", $$v)},expression:"form.id_ex_month"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])}),_c('validation-provider',{attrs:{"name":"ID Expire Year"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{staticClass:"hidden",model:{value:(_vm.form.id_ex_year),callback:function ($$v) {_vm.$set(_vm.form, "id_ex_year", $$v)},expression:"form.id_ex_year"}}),_c('small',{staticClass:"text-danger"},[_c('div',[_vm._v(_vm._s(errors[0]))])])]}}])})],1),_c('b-form-group',{attrs:{"label":"รหัสหลังบัตรประชาชน"}},[_c('b-row',[_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"digits:3","name":"laser1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"XXX","maxlength":"3"},on:{"input":function($event){return _vm.moveToNextField($event, 2, 3)}},model:{value:(_vm.form.laser_1),callback:function ($$v) {_vm.$set(_vm.form, "laser_1", $$v)},expression:"form.laser_1"}})]}}])})],1)],1),_c('h4',[_vm._v("_")]),_c('b-col',{attrs:{"cols":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"digits:7","name":"laser2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{ref:"laser2",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"XXXXXXX","maxlength":"7"},on:{"input":function($event){return _vm.moveToNextField($event, 3, 7)}},model:{value:(_vm.form.laser_2),callback:function ($$v) {_vm.$set(_vm.form, "laser_2", $$v)},expression:"form.laser_2"}})]}}])})],1)],1),_c('h4',[_vm._v("_")]),_c('b-col',[_c('b-form-group',[_c('validation-provider',{attrs:{"rules":"digits:2","name":"laser3"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('b-form-input',{ref:"laser3",attrs:{"state":errors.length > 0 ? false : null,"placeholder":"XX","maxlength":"2"},model:{value:(_vm.form.laser_3),callback:function ($$v) {_vm.$set(_vm.form, "laser_3", $$v)},expression:"form.laser_3"}})]}}])})],1)],1)],1)],1),_c('b-button',{attrs:{"variant":"success"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_vm._v(" สมัครสมาชิก ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }