<template>
    <div>
        <vue-autosuggest
            :suggestions="filteredOptions"
            :limit="80"
            :input-props="{id:'autosuggest__input',class:'form-control' ,state:'false', placeholder:'ค้นหาจาก แขวง เขต รหัสไปรษณีย์'}"
            @input="onInputChange"
            @selected="selectHandler"
            variant="danger"
            autocomplete="off"
        >
            <template slot-scope="{suggestion}">
                <span>{{ suggestion.item.district }}</span> => 
                <span>{{ suggestion.item.amphoe }}</span> => 
                <span>{{ suggestion.item.province }}</span> => 
                <span>{{ suggestion.item.zipcode }}</span>
            </template>
        </vue-autosuggest>
    </div>
</template>

<script>
import { BFormGroup, BFormInput, BForm } from "bootstrap-vue";
import { VueAutosuggest } from 'vue-autosuggest'
import addressJson from "@/assets/thai_address_database.json";

export default {
    components: {
       BFormGroup, BFormInput, BForm, VueAutosuggest
    },
    data() {
        return {
            datasuggest: [],
            filteredOptions: [],
            limit: 50,
            selected: null,
            address_json:addressJson,
            addressSelected:null
        }
    },
    created() {
        // console.log(this.address_json)
        
    },
    methods: {
        selectHandler(text){
            console.log('selected',text)
            this.$emit('addressSelected',text)
        },
        onInputChange(text) {
            if (text === '' || text === undefined) {
                return
            }
            console.log('ok')
            let filteredData = this.address_json.filter(x=> Object.values(x).some(val => 
                String(val).includes(text) 
            ))

            filteredData.map(x=>{
                x.name = x.district+' => '+x.amphoe+' => '+x.province+' => '+x.zipcode
            })
            this.filteredOptions = [{
                data:filteredData
            }]
            
        },
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
