<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-end pb-1">
        <b-button
          class="mr-1"
          size="sm"
          @click="goToBlacklistTable"
          variant="relief-danger"
        >
          View Blacklist
        </b-button>

        <b-button size="sm" variant="relief-success" v-b-modal.modal-add-member>
          <feather-icon icon="PlusIcon" /> เพิ่มสมาชิก
        </b-button>
      </div>
      <list-table />
    </b-card>
    <add-member-modal></add-member-modal>
  </div>
</template>

<script>
import { BCard, BCardText, BButton } from "bootstrap-vue";
import ListTable from "./component/ListTable.vue";
import AddMemberModal from "./component/AddMemberModal.vue";
import AddressSelector from "./component/AddressSelector.vue";

export default {
  components: {
    BCard,
    BCardText,
    ListTable,
    BButton,
    AddMemberModal,
    AddressSelector,
  },
  methods: {
    goToBlacklistTable() {
      this.$router.push({ name: "blacklist-list" });
    },
  },
};
</script>

<style></style>
