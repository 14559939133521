<template>
  <div>
    <!-- วันเกิด -->
    <validation-observer ref="addMember">
      <b-row>
        <b-col cols="3">
          <span>{{ day_text }}</span>
          <v-select
            v-model="select.day"
            label="title"
            :options="day_list"
            :clearable="false"
            @input="picker_update()"
          />
        </b-col>
        <b-col>
          <span>เดือน</span>
          <v-select
            v-model="select.month"
            label="title"
            :options="month_list"
            :clearable="false"
            @input="picker_update()"
          />
        </b-col>
        <b-col cols="4">
          <span>ปี</span>
          <v-select
            v-model="select.year"
            label="title"
            :options="year_list"
            :clearable="false"
            @input="picker_update()"
          />
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BForm } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import vSelect from "vue-select";

export default {
  components: {
    BFormGroup,
    BFormInput,
    BForm,
    vSelect,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
  },
  props: ["day_text", "year_range", "prop_select"],
  created() {
    this.day_list_gen();
    this.year_list_generate(this.year_range.max_year, this.year_range.min_year);
    if (this.prop_select) {
      this.select = this.prop_select;
    }
    this.picker_update();
  },
  watch: {
    "select.month.value"(newVal) {
      this.select.month = this.month_list.find(
        (month) => month.value === newVal
      );
    },
    // Add watchers for day and year if needed
  },
  data() {
    return {
      select: {
        day: {
          title: null,
          value: null,
        },
        month: {
          title: null,
          value: null,
        },
        year: {
          title: null,
          value: null,
        },
      },
      selected1: {
        title: "Aperture",
        icon: "ApertureIcon",
      },
      day_list: [],
      month_list: [
        { name: "b_month", value: 1, title: "มกราคม", max_days: 31 },
        { name: "b_month", value: 2, title: "กุมภาพันธ์", max_days: 29 },
        { name: "b_month", value: 3, title: "มีนาคม", max_days: 31 },
        { name: "b_month", value: 4, title: "เมษายน", max_days: 30 },
        { name: "b_month", value: 5, title: "พฤษภาคม", max_days: 31 },
        { name: "b_month", value: 6, title: "มิถุนายน", max_days: 30 },
        { name: "b_month", value: 7, title: "กรกฎาคม", max_days: 31 },
        { name: "b_month", value: 8, title: "สิงหาคม", max_days: 31 },
        { name: "b_month", value: 9, title: "กันยายน", max_days: 30 },
        { name: "b_month", value: 10, title: "ตุลาคม", max_days: 31 },
        { name: "b_month", value: 11, title: "พฤศจิกายน", max_days: 31 },
        { name: "b_month", value: 12, title: "ธันวาคม", max_days: 31 },
      ],
      year_list: [],
    };
  },
  methods: {
    day_list_gen() {
      for (let x = 1; x <= 31; x += 1) {
        this.day_list.push({ name: "b_day", value: x, title: x });
      }
    },
    year_list_generate(max, min) {
      this.year_list = [];

      for (let year = max; year >= min; year -= 1) {
        this.year_list.push({ name: "b_year", value: year, title: year });
      }
    },
    picker_update() {
      if (
        (this.select.day.value != null) &
        (this.select.month.value != null) &
        (this.select.year.value != null)
      ) {
        this.$emit("PickerUpdate", {
          day: this.select.day.value,
          month: this.select.month.value,
          year: this.select.year.value,
        });
      }
    },
  },
};
</script>
